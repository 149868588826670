<template>
  <b-popover class="p-0" :target="target" triggers="click blur" placement="right">
    <div class="mb-2">
      <b-button
        variant="secondary"
        v-if="record.is_total || hasPermission(module, 'payment')"
        class="btn-icon btn_icon margin-right w-100"
        size="lg"
        @click.prevent="() => $emit('payments', record)"
      >
        <img
          src="@/assets/images/boleto.png"
          width="15px"
          height="15px"
          class="mr-1"
          alt=""
        />
        Pagos
      </b-button>
    </div>
    <div class="mb-2">
      <b-button
        v-if="hasPermission(module, 'detail')"
        variant="primary"
        class="btn-icon btn_icon margin-right w-100"
        size="lg"
        @click.prevent="() => $emit('detail', record)"
      >
        <b-icon-eye-fill class="mr-1" />
        Detalle
      </b-button>
    </div>
    <div class="mb-2">
      <b-button
        v-if="hasPermission(module, 'edit')"
        variant="warning"
        class="btn-icon btn_icon margin-right w-100"
        size="lg"
        @click.prevent="() => $emit('editing', record)"
      >
        <b-icon-pencil-square class="mr-1" />
        Editar
      </b-button>
    </div>
    <div class="">
      <b-button
        v-if="isVisible && hasPermission(module, 'delete')"
        variant="danger"
        class="btn-icon btn_icon w-100"
        size="lg"
        @click.prevent="() => $emit('deleting', record.uuid)"
      >
        <b-icon-trash-fill class="mr-1" />
        Eliminar
      </b-button>
    </div>
  </b-popover>
</template>

<script>
import {
  BButton,
  BIconTrashFill,
  BPopover,
  BIconPencilSquare,
  BIconEyeFill,
} from "bootstrap-vue";
import PermissionAction from "@/mixins/PermissionAction";

export default {
  mixins: [PermissionAction],
  components: {
    BButton,
    BIconTrashFill,
    BPopover,
    BIconPencilSquare,
    BIconEyeFill,
  },
  props: {
    target: {
      type: String,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    module: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
