<template>
  <section>
    <b-row>
      <b-col lg="4" md="3" class="mb-2">
        <slot name="left-panel" />
      </b-col>
      <b-col lg="4" md="2" />
      <b-col lg="4" md="7" sm="12" class="mb-3">
        <div role="group" class="d-flex align-items-center" v-if="filtering">
          <b-form-input
            size="md"
            v-model="filter"
            type="search"
            placeholder="Buscar"
            class="mr-3"
          />
          <b-input-group-append>
            <b-button variant="danger" size="md" :disabled="!filter" @click="filter = ''">
              Limpiar
            </b-button>
          </b-input-group-append>
        </div>
      </b-col>
    </b-row>

    <b-table
      striped
      hover
      responsive
      bordered
      small
      :fields="fields"
      :items="records"
      :filter="filter"
      :per-page="dataPerPage"
      :current-page="pagination.currentPage"
      :busy="busy"
      show-empty
      :filter-included-fields="filterOn"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Cargando datos...</strong>
        </div>
      </template>
      <template #empty>
        <h6 class="text-center py-2">No hay registros</h6>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </b-table>
    <b-row v-if="false">
      <b-col cols="12" class="my-1">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          pills
          align="center"
          size="sm"
          class="my-0"
          prev-text="Anterior"
          next-text="Siguiente"
        ></b-pagination>
      </b-col>
    </b-row>
  </section>
</template>

<script lang="js">
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BPagination,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BCol,
    BRow,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BPagination,
    BSpinner,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    filtering: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filter: null,
      pagination: {
        perPage: 10,
        pageOptions: [10, 20, 50],
        totalRows: 1,
        currentPage: 1,
      },
      filterOn: [],
      sortDesc: false,
      sortDirection: "asc",
    };
  },
  computed: {
    dataPerPage() {
      const me = this;
      return me.paginate ? me.pagination.perPage : me.records.length;
    },
  },
  mounted() {
    this.pagination.totalRows = this.records.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.pagination.currentPage = 1;
    },
  },
};
</script>
<style lang="css">
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.table > thead {
  background: var(--fourth-color);
  color: var(--white-color);
  font-family: var(--fuente-primary);
  font-size: 16px;
  text-align: center;
}

.table > tbody {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-size: 16px;
}
</style>
