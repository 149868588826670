<template>
  <div>
    <base-table
      :records="records"
      :fields="fields"
      :busy="busy"
      :filtering="filtering"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
      <template v-slot:left-panel>
        <b-button v-if="isVisible && hasPermission(module, 'new')" size="md" variant="success" :disabled="disabled" @click="() => $emit('creating')">
          <b-icon-plus-square class="mr-1" />
          Nuevo
        </b-button>
      </template>
      <template #cell(actions)="data">
        <div class="d-flex align-items-center justify-content-center">
          <slot name="extendedactions" :data="data"> &nbsp; </slot>
          <b-button
            :id="'p-' + data.item.uuid"
            class="btn_icon actions_ margin-right"
            size="md"
          >
            <b-icon-three-dots-vertical />
          </b-button>
        </div>
        <Popover
          :target="'p-' + data.item.uuid"
          :record="data.item"
          :module="module"
          :isVisible="isVisible"
          @detail="detail"
          @editing="editing"
          @deleting="deleting"
          @payments=payments
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import {
  BButton,
  BIconPlusSquare,
  BIconThreeDotsVertical,
} from "bootstrap-vue";
import BaseTable from "./BaseTable";
import Popover from "./Popover";
import PermissionAction from "@/mixins/PermissionAction";

export default {
  components: {
    BButton,
    BIconPlusSquare,
    BIconThreeDotsVertical,
    BaseTable,
    Popover,
  },
  mixins: [PermissionAction],
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    filtering: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    module: {
      type: String,
      default: '',
    },
  },
  methods: {
    detail(item) {
      this.$emit('detail', item);
    },
    editing(item) {
      this.$emit('editing', item);
    },
    deleting(uuid) {
      this.$emit('deleting', uuid);
    },
    payments(item) {
      this.$emit('payments', item);
    },
  }
};
</script>

<style lang="css">
.btn_icon {
  color: var(--white-color);
}

.margin-right {
  margin-right: 10px;
}

.actions_ {
  background: var(--white-color) !important;
  border: 1px solid var(--second-color) !important;
  color: var(--black-color) !important;
  font-weight: bold !important;
}
</style>
